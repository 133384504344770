export default {
  async update (Vue, id, formData) {
    await Vue.$api.call(
      'materialSistema.update',
      {
        values: {
          idmaterial_sistema: id,
          fsistema: formData.fsistema,
          descripcion: formData.descripcion,
          unidades: formData.unidades,
          num_zona: formData.num_zona,
          evento: formData.evento,
          ubicacion: formData.ubicacion,
          nserie: formData.nserie,
          observaciones: formData.observaciones,
          funcion: formData.funcion,
          ffabricacion: formData.ffabricacion,
          idfabricante: formData.idfabricante,
          fult_retimbrado: formData.fult_retimbrado,
          n_orden: formData.n_orden,
          fdesinstalacion: formData.fdesinstalacion,
          nelemento: formData.nelemento,
          codigo: formData.codigo,
          idagente_propelente: formData.idagente_propelente,
          idagente_extintor: formData.idagente_extintor,
          volumen: formData.volumen,
          eficacia: formData.eficacia,
          presion: formData.presion,
          peso_total: formData.peso_total,
          peso_agente_extintor: formData.peso_agente_extintor,
          fcaducidad: formData.fcaducidad,
          netiqueta: formData.netiqueta,
          observaciones_internas: formData.observaciones_internas,
          fultrevision: formData.fultrevision,
          particion: formData.particion,
          ffin_garantia: formData.ffin_garantia,
          idtimportancia_material: formData.idtimportancia_material,
        },
      },
    )
    if (formData.datosArticulo) {
      for (const tdato of formData.datosArticulo) {
        if (tdato.valor) {
          if (tdato.iddato_material_sistema) {
            await Vue.$api.call(
              'datoMaterialSistema.update',
              {
                values: {
                  iddato_material_sistema: tdato.iddato_material_sistema,
                  valor: tdato.valor
                }
              }
            )
          } else {
            await Vue.$api.call(
              'datoMaterialSistema.insert',
              {
                values: {
                  idmaterial_sistema: id,
                  idtdato_articulo: tdato.idtdato_articulo,
                  valor: tdato.valor,
                  estado: 1,
                }
              }
            )
          }
        } else {
          if (tdato.iddato_material_sistema) {
            await Vue.$api.call(
              'datoMaterialSistema.delete',
              {
                values: {
                  iddato_material_sistema: tdato.iddato_material_sistema
                }
              }
            )
          }
        }
      }
    }
  },
}
